import 'antd/dist/antd.less';
import {navigate, withPrefix} from 'gatsby';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import Modal from '../../Modals';
import {normalizePath} from '../../Utils/pathUtil';
import '../../index.css';
import ContactUsButton from '../ContactUsButton';
import Footer from '../Footer';
import Navbar from '../Navbar';
import Delighters from '../ReactDelighters';
import Toast from '../Toast';
import ScrollToTopButton from '../ScrollToTopButton';
const appConfig = require('../../data.json');

const Layout = ({location, children, seo}) => {
  const _pathname = normalizePath(location.pathname);
  const extraHeadMetaArray = appConfig['extra-head-meta'] || [];

  React.useLayoutEffect(() => {
    if (appConfig.maintenance && location.pathname !== '/maintenance/') {
      navigate('/maintenance/');
    }
  }, [location]);

  React.useLayoutEffect(() => {
    // ga4 initial
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', appConfig.googleAnalyticsId, {
        page_path: location.pathname,
        debug_mode: appConfig.debug,
      });
    }
  }, [location]);

  useEffect(() => {
    if (appConfig.enableGetButton) {
      const appendScript = (scriptToAppend) => {
        const script = document.createElement('script');
        script.src = scriptToAppend;
        document.body.appendChild(script);
      };

      setTimeout(() => {
        appendScript('https://static.getbutton.io/widget/bundle.js?id=UOmW9');
      }, 3000);
    }
  }, []);

  return (
    <main>
      <Helmet>
        {/* <link rel="shortcut icon" href="/favicon.ico" /> */}
        <title>{seo.title || appConfig.information.name}</title>
        {seo.keywords && <meta name="keywords" content={seo.keywords} />}

        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta name="description" content={seo.description} />
        )}
        {seo.image && <meta name="image" content={seo.image} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}

        {extraHeadMetaArray.map((m, idx) => (
          <meta key={idx} {...m} />
        ))}

        <meta
          name="google-site-verification"
          content="Ou6rjherlLcbL_aOdbvo81mMgKqzYr4kswI7yG_jMjo"
        />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="author" content="EcultureTech 奕果雲端數位股份有限公司" />

        <script src={'/yahoo-ad-tracking.js'} type="application/javascript" />
        <script src="https://api.payuni.com.tw/widget/sdk.bundle.js"></script>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${appConfig.googleAnalyticsId}`}
        />
      </Helmet>
      <Delighters>
        <Content>
          <Navbar pathname={_pathname} />
          <div
            style={{
              flex: 1,
              position: 'relative',
              minHeight: '70vh',
              display: 'flex',
              flexDirection: 'column',
            }}>
            {children}
          </div>
          <Footer />
        </Content>
      </Delighters>
      <Toast />
      <Modal />

      <ContactUsButton location={location} />
      <ScrollToTopButton />
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const Content = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export default Layout;
